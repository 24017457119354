body p.bubble {
  mix-blend-mode: multiply;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #774f38;
  z-index: 10;
  max-height: 50vh;
  background-color: #ece5ce;
  border-radius: 11px;
  padding: 25px;
  font-family: "Baloo 2", cursive;
  font-size: 25px;
  font-weight: bold;
  animation: 5s ease-in-out infinite float;
  position: relative;
  transform: translateY(0);
  box-shadow: 20px 20px #2e2e2e;
}

@media screen and (max-width: 600px) {
  body p.bubble {
    padding: 5vw;
    font-size: 5vw;
  }
}

@media screen and (min-width: 601px) {
  body p.bubble {
    padding: 25px;
    font-size: 25px;
  }
}

@media screen and (max-height: 400px) {
  body p.bubble {
    padding: 25px;
    font-size: 15px;
  }
}

body p.bubble:after {
  content: ".";
  -webkit-text-fill-color: #ece5ce;
  text-shadow: 22px 22px #83af9b;
  text-shadow: 22px 22px #2e2e2e;
  text-align: center;
  width: 55px;
  height: 11px;
  z-index: -2;
  background-color: #ece5ce;
  border-radius: 11px;
  font-size: 55px;
  font-weight: bold;
  line-height: 30px;
  animation: 5s ease-in-out infinite float2;
  display: block;
  position: absolute;
  bottom: -30px;
  left: 50%;
  box-shadow: 22px 22px #2e2e2e;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translateY(0);
  }

  55% {
    transform: translateY(-20px);
  }

  60% {
    line-height: 10px;
  }

  100% {
    line-height: 30px;
    transform: translateY(0);
  }
}

@keyframes float3 {
  0% {
    line-height: 30px;
    transform: rotate(290deg)translate(70px, 40px);
  }

  55% {
    transform: rotate(290deg)translate(70px, 20px);
  }

  60% {
    line-height: 10px;
  }

  100% {
    line-height: 30px;
    transform: rotate(290deg)translate(70px, 40px);
  }
}

.v-btn {
  color: #fff;
  opacity: .5;
  text-decoration: none;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0;
}

.v-btn:hover {
  opacity: 1;
}

.v-btn.prev-v-btn {
  background: linear-gradient(135deg, #333 50%, #0000 50%);
  padding: .5rem 3rem 3rem .5rem;
  left: 0;
}

.v-btn.next-v-btn {
  background: linear-gradient(45deg, #0000 50%, #333 50%);
  padding: .5rem .5rem 3rem 3rem;
  right: 0;
}

/*# sourceMappingURL=index.dbc7210b.css.map */
